body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}
a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '`';
}

/*
=====================================================================
*   Portfolio Layout Stylesheet
*   version: 0.1.0
*   modified: 05-20-2020
=====================================================================

   TOC:
   a. General Styles
   b. Tech Stack Section

===================================================================== */
/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */
body {
  background: #1a1b15;
  color: #FFFFFF;
  font: 12px 'Nanum Gothic', sans-serif;
  text-align: center; }

h2.tagline {
  letter-spacing: 14px;
  font-size: 30px;
  margin: 0;
  margin-left: 14px;
  font-weight: 700; }

/* ------------------------------------------------------------------ */
/* b. Navigation Styles
/* ------------------------------------------------------------------ */
#nav-wrap {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  fill-opacity: 0.5; }

#nav-wrap ul, #nav-wrap li, #nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none; }

ul#nav li {
  display: inline-block;
  list-style: none; }

#nav li a {
  display: inline-block;
  padding: 5px 15px;
  line-height: 32px;
  text-decoration: none;
  text-align: left;
  color: #fff;
  transition: color .2s ease-in-out; }

ul#nav {
  min-height: 50px;
  width: auto; }

ul#nav li a:active {
  background-color: transparent !important; }

ul#nav li.current a {
  color: #F06000; }

/* ------------------------------------------------------------------ */
/* c. Home Styles
/* ------------------------------------------------------------------ */
section.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(rgba(48, 48, 48, 0.6), rgba(48, 48, 48, 0.6)), url(/static/media/wild_rose.8c5c902e.jpg) no-repeat top center;
  background-size: cover !important; }

section.home:after {
  content: '';
  position: fixed;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2); }

.avatar-container {
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  background: url(/static/media/avatar.31a47808.png);
  background-size: cover;
  border-radius: 50%;
  margin-bottom: 50px; }

.home-container {
  width: 50%;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 25px;
  padding: 2rem 0.5rem; }
  .home-container h1.headline {
    letter-spacing: 8px;
    font-size: 44px;
    font-weight: 700;
    margin: 0px;
    line-height: 50px; }
  .home-container p.description {
    font-size: 18px;
    width: 100%; }
    .home-container p.description a {
      text-decoration: underline; }

.contact-container {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  position: absolute;
  bottom: 2.5em;
  z-index: 3; }

/* ------------------------------------------------------------------ */
/* d. About Styles
/* ------------------------------------------------------------------ */
section.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  min-height: 30vh; }
  section.about h2.headline {
    font-size: 60px;
    margin: 0;
    font-weight: 700; }

.about-container {
  text-align: left;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  align-content: center;
  flex-flow: row wrap;
  margin-top: 2.4rem; }
  .about-container .description {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    font-size: 16px;
    padding-bottom: 1rem;
    padding-top: 1rem; }

/* ------------------------------------------------------------------ */
/* e. Tech Stack Styles
/* ------------------------------------------------------------------ */
section.tech-stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  min-height: 30vh; }

.tools-container {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: solid 1px white;
  border-radius: 12px;
  padding: 1rem;
  align-content: center;
  flex-flow: row wrap;
  margin-top: 2.4rem; }
  .tools-container .tech_tool {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem;
    font-size: 16px; }

/* ------------------------------------------------------------------ */
/* f. Contact Styles
/* ------------------------------------------------------------------ */
section.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  min-height: 30vh; }

